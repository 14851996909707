import { ProjectUrl } from "~/env";
import Alertify from "~/scripts/alertify";
import Nanobar from "~/scripts/nanobar";

import { ANALYTICS_SET_DASHBOARD_INITIAL_DATA, ANALYTICS_SET_GRAPH_INITIAL_DATA } from "~/actions/types";

/** @desc function for getting initial data for dashboard */
export const AnalyticsGetDashboardInitialData = () => (dispatch) => {
	Nanobar.start();
	fetch(ProjectUrl + "/analytics/getdashboarddata", {
		headers: { "X-Requested-With": "XMLHttpRequest" },
	}).then((res) =>
		(res.ok ? res.json() : Promise.reject(res))
			.then((data) => {
				dispatch({ type: ANALYTICS_SET_DASHBOARD_INITIAL_DATA, data });
				Nanobar.stop();
			})
			.catch((error) => {
				if (error instanceof Error) Alertify.error(error.message);
				else if (error !== undefined && error !== null) error.text().then((data) => Alertify.error(data));
				else Alertify.error("An error has occurred");
				Nanobar.stop();
			})
	);
};

/** @desc function for getting initial data for analytics page */
export const AnalyticsGetGraphsInitialData = () => (dispatch) => {
	Nanobar.start();
	fetch(ProjectUrl + "/analytics/getanalyticsdata", {
		headers: { "X-Requested-With": "XMLHttpRequest" },
	})
		.then((res) => (res.ok ? res.json() : Promise.reject(res)))
		.then((data) => {
			dispatch({ type: ANALYTICS_SET_GRAPH_INITIAL_DATA, data });
			Nanobar.stop();
		})
		.catch((error) => {
			if (error instanceof Error) Alertify.error(error.message);
			else if (error !== undefined && error !== null) error.text().then((data) => Alertify.error(data));
			else Alertify.error("An error has occurred");
			Nanobar.stop();
		});
};
