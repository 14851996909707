import { useState } from "react";

const useLoader = (loaderValue) => {
	const [loader, setLoader] = useState(loaderValue);

	const startLoader = () => {
		setLoader(true);
	};

	const stopLoader = () => {
		setLoader(false);
	};

	return [loader, startLoader, stopLoader];
};

export default useLoader;
