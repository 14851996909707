/** COMMON TYPES */
export const RESET_INITIAL_STATE_DATA = "RESET_INITIAL_STATE_DATA";

/** REHYDRATION STORE */
export const REHYDRATION_SET_REHYDRATION_VALUE = "REHYDRATION_SET_REHYDRATION_VALUE";

/*** REPORTS STORE */
export const REPORTS_SET_DATE = "REPORTS_SET_DATE";
export const REPORTS_CLEAR_LOCAL_DATA = "REPORTS_CLEAR_LOCAL_DATA";

/*** ANALYTICS STORE ***/
export const ANALYTICS_SET_DASHBOARD_INITIAL_DATA = "ANALYTICS_SET_DASHBOARD_INITIAL_DATA";
export const ANALYTICS_SET_GRAPH_INITIAL_DATA = "ANALYTICS_SET_GRAPH_INITIAL_DATA";

/*** ISSUES STORE ***/
export const ISSUES_SET_SEARCH_INPUT = "ISSUES_SET_SEARCH_INPUT";
export const ISSUES_SET_FILTER_VALUE = "ISSUES_SET_FILTER_VALUE";
export const ISSUES_APPEND_ISSUES_LIST = "ISSUES_APPEND_ISSUES_LIST";
export const ISSUES_SET_ISSUES_LIST = "ISSUES_SET_ISSUES_LIST";
export const ISSUES_CLEAR_LOCAL_STATE = "ISSUES_CLEAR_LOCAL_STATE";

/*** FEEDBACKS STORE ***/
export const FEEDBACK_SET_SORT_FILTER_VALUE = "FEEDBACK_SET_SORT_FILTER_VALUE";
export const FEEDBACK_OPEN_REPLY_FIELD = "FEEDBACK_OPEN_REPLY_FIELD";
export const FEEDBACK_SET_REPLY_TEXT = "FEEDBACK_SET_REPLY_TEXT";
export const FEEDBACK_CLOSE_REPLY_FIELD = "FEEDBACK_CLOSE_REPLY_FIELD";
export const FEEDBACK_SET_REVIEWS_LIST = "FEEDBACK_SET_REVIEWS_LIST";
export const FEEDBACK_APPEND_REVIEWS_LIST = "FEEDBACK_APPEND_REVIEWS_LIST";
export const FEEDBACK_UPDATE_FLAG_REVIEW = "FEEDBACK_UPDATE_FLAG_REVIEW";
export const FEEDBACK_CREATE_UPDATE_REPLY = "FEEDBACK_CREATE_UPDATE_REPLY";
export const FEEDBACK_OPEN_FLAG = "FEEDBACK_OPEN_FLAG";
export const FEEDBACK_SET_FLAG_STATUS = "FEEDBACK_SET_FLAG_STATUS";
export const FEEDBACK_SET_INITIAL_STATE_DATA = "FEEDBACK_SET_INITIAL_STATE_DATA";
export const FEEDBACK_CLEAR_LOCAL_STATE = "FEEDBACK_CLEAR_LOCAL_STATE";
/*** QUESTIONS STORE ***/
export const QUESTIONS_SET_SEARCH_INPUT = "QUESTIONS_SET_SEARCH_INPUT";
export const QUESTIONS_SET_SEARCH_SORT_FILTER_DATA = "QUESTIONS_SET_SEARCH_SORT_FILTER_DATA";
export const QUESTIONS_SET_EDIT_QUESTION = "QUESTIONS_SET_EDIT_QUESTION";
export const QUESTIONS_OPEN_EDIT_QUESTIONS_FIELD = "QUESTIONS_OPEN_EDIT_QUESTIONS_FIELD";
export const QUESTIONS_CLOSE_EDIT_QUESTIONS_FIELD = "QUESTIONS_CLOSE_EDIT_QUESTIONS_FIELD";
export const QUESTIONS_SET_ANSWER = "QUESTIONS_SET_ANSWER";
export const QUESTIONS_OPEN_ANSWER_FIELD = "QUESTIONS_OPEN_ANSWER_FIELD";
export const QUESTIONS_CLOSE_ANSWER_FIELD = "QUESTIONS_CLOSE_ANSWER_FIELD";
export const QUESTIONS_UPDATE_ANSWER = "QUESTIONS_UPDATE_ANSWER";
export const QUESTIONS_SET_FLAG_STATUS = "QUESTIONS_SET_FLAG_STATUS";
export const QUESTIONS_OPEN_FLAG_MODAL = "QUESTIONS_OPEN_FLAG_MODAL";
export const QUESTIONS_SET_QUESTIONS_LIST = "QUESTIONS_SET_QUESTIONS_LIST";
export const QUESTIONS_APPEND_QUESTIONS_LIST = "QUESTIONS_APPEND_QUESTIONS_LIST";
export const QUESTIONS_CREATE_ANSWER = "QUESTIONS_CREATE_ANSWER";
export const QUESTIONS_UPDATE_FLAG_QUESTION = "QUESTIONS_UPDATE_FLAG_QUESTION";
export const QUESTIONS_UPDATE_QUESTION = "QUESTIONS_UPDATE_QUESTION";
export const QUESTIONS_CLEAR_LOCAL_STATE = "QUESTIONS_CLEAR_LOCAL_STATE";

/***DOCTOR***/
export const DOCTOR_SET_INPUT_TEXT = "DOCTOR_SET_INPUT_TEXT";
export const DOCTOR_SET_VALIDATION_ERROR = "DOCTOR_SET_VALIDATION_ERROR";
export const DOCTOR_SET_TERMS_ACCEPT_CHECKBOX = "DOCTOR_SET_TERMS_ACCEPT_CHECKBOX";
export const DOCTOR_OPEN_ANSWER_FIELD = "DOCTOR_OPEN_ANSWER_FIELD";
export const DOCTOR_SET_ANSWER_TEXT = "DOCTOR_SET_ANSWER_TEXT";
export const DOCTOR_CLOSE_ANSWER_FIELD = "DOCTOR_CLOSE_ANSWER_FIELD";
export const DOCTOR_SET_SUPPORT_CODE = "DOCTOR_SET_SUPPORT_CODE";
export const DOCTOR_CREATE_ANSWER = "DOCTOR_CREATE_ANSWER";
export const DOCTOR_SET_USER_CREDENTIALS = "DOCTOR_SET_USER_CREDENTIALS";
export const DOCTOR_SET_TERMS_OF_USE = "DOCTOR_SET_TERMS_OF_USE";
export const DOCTOR_CLEAR_CHANGED_PASSWORD_INPUTS_TEXTS = "DOCTOR_CLEAR_CHANGED_PASSWORD_INPUTS_TEXTS";
export const DOCTOR_SET_INITIAL_TEST_QUESTIONS_DATA = "DOCTOR_SET_INITIAL_TEST_QUESTIONS_DATA";
export const DOCTOR_CLEAR_LOCAL_STATE = "DOCTOR_CLEAR_LOCAL_STATE";

/*** PROFILE PAGE ***/
export const PROFILE_SET_LOCATION_DATA = "PROFILE_SET_LOCATION_DATA";
export const PROFILE_SET_LANGUAGE = "PROFILE_SET_LANGUAGE";
export const PROFILE_SET_DATALIST = "PROFILE_SET_DATALIST";
export const PROFILE_GET_HISTORY_DATA = "PROFILE_GET_HISTORY_DATA";
export const PROFILE_SET_LOCATION_IN_DATALIST = "PROFILE_SET_LOCATION_IN_DATALIST";
export const PROFILE_SET_BIO_PROFILE_IN_DATALIST = "PROFILE_SET_BIO_PROFILE_IN_DATALIST";
export const PROFILE_SET_LANGUAGES_IN_DATALIST = "PROFILE_SET_LANGUAGES_IN_DATALIST";
export const PROFILE_SET_ALL_STATES = "PROFILE_SET_ALL_STATES";
export const PROFILE_SET_BIO_TEXT = "PROFILE_SET_BIO_TEXT";
export const PROFILE_RESET_PROFILE_MODALS_DATA = "PROFILE_RESET_PROFILE_MODALS_DATA";
export const PROFILE_SET_EDUCATION_MODAL_DATA = "PROFILE_SET_EDUCATION_MODAL_DATA";
export const PROFILE_SET_EXPERIENCE_MODAL_DATA = "PROFILE_SET_EXPERIENCE_MODAL_DATA";
export const PROFILE_SET_AWARD_MODAL_DATA = "PROFILE_SET_AWARD_MODAL_DATA";
export const PROFILE_SET_CERTIFICATE_MODAL_DATA = "PROFILE_SET_CERTIFICATE_MODAL_DATA";
export const PROFILE_SET_REGISTRATION_MODAL_DATA = "PROFILE_SET_REGISTRATION_MODAL_DATA";
export const PROFILE_SET_MEMBERSHIP_MODAL_DATA = "PROFILE_SET_MEMBERSHIP_MODAL_DATA";
export const PROFILE_SET_BANK_MODAL_DATA = "PROFILE_SET_BANK_MODAL_DATA";
export const PROFILE_SET_PAN_MODAL_DATA = "PROFILE_SET_PAN_MODAL_DATA";
export const PROFILE_CLEAR_LOCAL_STATE = "PROFILE_CLEAR_LOCAL_STATE";
export const PROFILE_TOGGLE_BIO_FIELD_VISIBILITY = "PROFILE_TOGGLE_BIO_FIELD_VISIBILITY";

/*** REGISTER ***/
export const IS_REGISTERED = "IS_REGISTERED";
export const CHANGE_AUTHENTICATION_DATA = "CHANGE_AUTHENTICATION_DATA";
export const VALID_OTP = "VALID_OTP";
export const SAVE_PERSONAL_DETAILS = "SAVE_PERSONAL_DETAILS";
export const SELECTED_SPECIALIZATION = "SELECTED_SPECIALIZATION";
export const LOAD_SPECIALIZATION = "LOAD_SPECIALIZATION";
export const ADD_REMOVE_EDUCATION = "ADD_REMOVE_EDUCATION";
export const TOGGLE_CHANGE_EDUCATION = "TOGGLE_CHANGE_EDUCATION";
export const ADD_REMOVE_REGISTRATION = "ADD_REMOVE_REGISTRATION";
export const TOGGLE_CHANGE_REGISTRATION = "TOGGLE_CHANGE_REGISTRATION";
export const CHANGE_DOCUMENT_DATA = "CHANGE_DOCUMENT_DATA";
export const SIGN_UP_SUCCESS = "SIGN_UP_SUCCESS"; 
export const CHANGE_INSTITUTION = "CHANGE_INSTITUTION";
export const CHANGE_ACTIVE_STEP = "CHANGE_ACTIVE_STEP";
export const SET_SPECIALIZATION_ERRORS = "SET_SPECIALIZATION_ERRORS";
export const SET_OTP_STATE = "SET_OTP_STATE";

/*** CONSULTATIONS STORE ***/
export const CHANGE_CONSULTATIONS_DATA = "CHANGE_CONSULTATIONS_DATA";
export const SET_PATIENT_CONSULTATION_DATA = "SET_PATIENT_CONSULTATION_DATA";
export const CONSULTATION_SET_CONSULTATIONS_DATA = "CONSULTATION_SET_CONSULTATIONS_DATA";
export const TOGGLE_CHAT_SUMMARY = "TOGGLE_CHAT_SUMMARY";
export const CLEAR_CONSULTATION = "CLEAR_CONSULTATION";
export const CONSULTATION_SET_PRESCRIPTION_TAB = "CONSULTATION_SHOW_PRESCRIPTION_TAB";
export const CLEAR_POOL = "CLEAR_POOL";
export const START_CONSULTATION = "START_CONSULTATION";
export const ADD_CHAT = "ADD_CHAT";
export const REPLACE_CHAT = "REPLACE_CHAT";
export const TYPING_STATUS = "TYPING_STATUS";
export const CHANGE_STATE_30 = "CHANGE_STATE_30";
export const CHANGE_STATE_20 = "CHANGE_STATE_20";
export const UNREAD_CHAT = "UNREAD_CHAT";
export const READ_CHAT = "READ_CHAT";
export const PAGE_VISIBLE = "PAGE_VISIBLE";
export const CONSULTATION_SET_MOBILE_VIEW_TAB = "CONSULTATION_SET_MOBILE_VIEW_TAB";
export const CONNECTION_SIGNALR = "CONNECTION_SIGNALR";
export const FEEDBACK_REQUEST = "FEEDBACK_REQUEST";
export const POOL_CONSULTATION_PICKED = "POOL_CONSULTATION_PICKED";
export const ADD_CANNED_RES = "ADD_CANNED_RES";
export const DELETE_CANNED_RES = "DELETE_CANNED_RES";
export const ADD_POOL_ORDER = "ADD_POOL_ORDER";
export const CLOSE_LIGHTBOX = "CLOSE_LIGHTBOX";
export const SHOW_LIGHTBOX = "SHOW_LIGHTBOX";
export const CHANGE_SELECTED_CANNED_MSG = "CHANGE_SELECTED_CANNED_MSG";
export const CLOSE_CONSULTATION = "CLOSE_CONSULTATION";
export const CHANGE_CASE_SUMMARY = "CHANGE_CASE_SUMMARY";
export const CHANGE_CUSTOMER_ACTIVITY_STATUS = "CHANGE_CUSTOMER_ACTIVITY_STATUS";
export const CHANGE_NOTE = "CHANGE_NOTE";
export const CONSULTATION_CREATE_DOCTOR_NOTES = "CONSULTATION_CREATE_DOCTOR_NOTES";
export const CONSULTATIONS_SET_INITIAL_CONSULTATIONS_DATA = "CONSULTATIONS_SET_INITIAL_CONSULTATIONS_DATA";
export const CONSULTATION_CLEAR_LOCAL_STATE = "CONSULTATION_CLEAR_LOCAL_STATE";
export const CONSULTATION_SET_SEARCH_TEXT = "CONSULTATION_SET_SEARCH_TEXT";
export const CONSULTATION_SWITCH_TO_HISTORY_TAB = "CONSULTATION_SWITCH_TO_HISTORY_TAB";
export const CONSULTATION_ACTIVE_PRESCRIPTION_OR_MEDIA_TAB = "CONSULTATION_ACTIVE_PRESCRIPTION_OR_MEDIA_TAB";
export const CONSULTATION_SET_ISSUE_REASON = "CONSULTATION_SET_ISSUE_REASON";
export const CONSULTATION_SET_ISSUE_NOTE = "CONSULTATION_SET_ISSUE_NOTE";
export const CONSULTATION_CLEAR_ISSUE_REASON_NOTE = "CONSULTATION_CLEAR_ISSUE_REASON_NOTE";
export const CONSULTATION_SET_SORT_PAGE_TYPE_VALUE = "CONSULTATION_SET_SORT_PAGE_TYPE_VALUE";
export const CONSULTATION_SET_LEFT_MENU_LOADER = "CONSULTATION_SET_LEFT_MENU_LOADER";
export const CONSULTATION_SET_HISTORY_ID = "CONSULTATION_SET_HISTORY_ID";
export const CONSULTATION_SET_PATIENT_ID = "CONSULTATION_SET_PATIENT_ID";
export const CONSULTATION_SET_ISSUE_REASON_LIST = "CONSULTATION_SET_ISSUE_REASON_LIST";
export const CONSULTATION_SET_CANNED_MSG = "CONSULTATION_SET_CANNED_MSG";
export const CONSULTATION_SET_CHAT_MESSAGE_TEXT = "CONSULTATION_SET_CHAT_MESSAGE_TEXT";
export const CONSULTATION_OPEN_CANNED_MSG_INPUT_FIELD = "CONSULTATION_OPEN_CANNED_MSG_INPUT_FIELD";
export const CONSULTATION_CLOSE_CANNED_MSG_INPUT_FIELD = "CONSULTATION_CLOSE_CANNED_MSG_INPUT_FIELD";
export const CONSULTATION_OPEN_PRESCRIPTION_DETAILS_IN_MODAL_VIEW = "CONSULTATION_OPEN_PRESCRIPTION_DETAILS_IN_MODAL_VIEW";
export const CONSULTATION_SET_PRESCRIPTION = "CONSULTATION_SET_PRESCRIPTION";

/*** PRESCRIPTION STORE ***/
export const PRESCRIPTION_GET_DRUG_DATA = "PRESCRIPTION_GET_DRUG_DATA";
export const PRESCRIPTION_REPLACE_PRESCRIPTION = "PRESCRIPTION_REPLACE_PRESCRIPTION";
export const PRESCRIPTION_SET_PRESCRIPTION_IN_FORM = "PRESCRIPTION_SET_PRESCRIPTION_IN_FORM";
export const PRESCRIPTION_CLEAR_PRESCRIPTION_IN_FORM = "PRESCRIPTION_CLEAR_PRESCRIPTION_IN_FORM";
export const PRESCRIPTION_SET_ADVICE_TEXT = "PRESCRIPTION_SET_ADVICE_TEXT";
export const PRESCRIPTION_SET_DIAGNOSIS_TEXT = "PRESCRIPTION_SET_DIAGNOSIS_TEXT";
export const PRESCRIPTION_CHANGE_FOLLOW_UP_DATE = "PRESCRIPTION_CHANGE_FOLLOW_UP_DATE";
export const PRESCRIPTION_ADD_PRODUCT_ROW = "PRESCRIPTION_ADD_PRODUCT_ROW";
export const PRESCRIPTION_REMOVE_PRODUCT_ROW = "PRESCRIPTION_REMOVE_PRODUCT_ROW";
export const PRESCRIPTION_SET_PRODUCT_DETAILS = "PRESCRIPTION_SET_PRODUCT_DETAILS";
export const PRESCRIPTION_SET_DRUG_DETAIL = "PRESCRIPTION_SET_DRUG_DETAIL";
export const PRESCRIPTION_TOGGLE_PRODUCT_MEDICINE_FIELD = "PRESCRIPTION_TOGGLE_PRODUCT_MEDICINE_FIELD";
export const PRESCRIPTION_SET_NOTE_PRODUCT_ID = "PRESCRIPTION_SET_NOTE_PRODUCT_ID";
export const PRESCRIPTION_DELETE_PRESCRIPTION_ERROR_ON_FOCUS = "PRESCRIPTION_DELETE_PRESCRIPTION_ERROR_ON_FOCUS";
export const PRESCRIPTION_SET_PRESCRIPTION_ERRORS = "PRESCRIPTION_SET_PRESCRIPTION_ERRORS";
export const SET_HEALTH_PLAN = "SET_HEALTH_PLAN";
export const PRESCRIPTION_SET_CANNED_INSTRUCTION_TEXT = "PRESCRIPTION_SET_CANNED_INSTRUCTION_TEXT";
export const PRESCRIPTION_OPEN_CANNED_INS_INPUT_FIELD = "PRESCRIPTION_OPEN_CANNED_INS_INPUT_FIELD";
export const PRESCRIPTION_CLOSE_CANNED_INS_INPUT_FIELD = "PRESCRIPTION_CLOSE_CANNED_INS_INPUT_FIELD";
export const PRESCRIPTION_SET_PRODUCT_SEARCH_TEXT = "PRESCRIPTION_SET_PRODUCT_SEARCH_TEXT";
export const PRESCRIPTION_SET_ACTIVE_MEDIA_SHARED_TAB = "PRESCRIPTION_SET_ACTIVE_MEDIA_SHARED_TAB";
export const PRESCRIPTION_TOGGLE_DIET_PLAN_FIELD = "PRESCRIPTION_TOGGLE_DIET_PLAN_FIELD";

/*** DIET PLAN STORE ***/
export const DIET_SET_MEAL_TIMING = "DIET_SET_MEAL_TIMING";
export const DIET_EDIT_DIET_PLAN_DAYS = "DIET_EDIT_DIET_PLAN_DAYS";
export const DIET_CLOSE_EDIT_DIET_PLAN_DAYS = "DIET_CLOSE_EDIT_DIET_PLAN_DAYS";
export const DIET_SET_EDIT_DIET_PLAN_DAYS = "DIET_SET_EDIT_DIET_PLAN_DAYS";
export const DIET_SET_MENU_QUANTITY_AND_ITEM = "DIET_SET_MENU_QUANTITY_AND_ITEM";
export const DIET_REMOVE_MENU_QUANTITY_AND_ITEM = "DIET_REMOVE_MENU_QUANTITY_AND_ITEM";
export const DIET_ADD_MENU_ITEM_ROW = "DIET_ADD_MENU_ITEM_ROW";
export const DIET_ADD_MEAL = "DIET_ADD_MEAL";
export const DIET_SET_EDITED_MEAL_DETAILS = "DIET_SET_EDITED_MEAL_DETAILS";
export const DIET_SELECT_CANNED_MEALS = "ADD_CANNED_MEALS";
export const DIET_REMOVE_MEAL = "DIET_REMOVE_MEAL";
export const DIET_SET_PLAN_FORM_ERRORS = "DIET_SET_PLAN_FORM_ERRORS";
export const DELETE_PLAN_FORM_ERROR = "DELETE_PLAN_FORM_ERROR";
export const DIET_SELECT_DIET_PLAN = "DIET_SELECT_DIET_PLAN";
export const DIET_ADD_PLAN_DAYS = "ADD_PLAN";
export const DIET_DELETE_DIET_PLAN_DAYS = "DIET_DELETE_DIET_PLAN_DAYS";
export const DIET_SET_DIET_PLAN_DAY = "DIET_SET_DIET_PLAN_DAY";
export const DIET_TOGGLE_WEEKLY_DIET_PLANS = "DIET_TOGGLE_WEEKLY_DIET_PLANS";
export const DIET_SET_PLAN_TITLE = "DIET_SET_PLAN_TITLE";
export const DIET_EDIT_DIET_PLANS = "DIET_EDIT_DIET_PLANS";
export const DIET_CLEAR_DIET_PLANS_FORM_DATA = "DIET_CLEAR_DIET_PLANS_FORM_DATA";
export const DIET_RESET_CALENDAR_DAYS = "DIET_RESET_CALENDAR_DAYS";
export const DIET_SET_CONFIRM_MODAL_DATA_ON_CANCEL_DIET_PLAN_FORM = "DIET_SET_CONFIRM_MODAL_DATA_ON_CANCEL_DIET_PLAN_FORM";
export const DIET_SET_CONFIRM_MODAL_DATA_ON_SAVE_DIET_PLAN_FORM = "DIET_SET_CONFIRM_MODAL_DATA_ON_SAVE_DIET_PLAN_FORM";
export const DIET_SET_CONFIRM_MODAL_DATA_ON_DELETE_DIET_PLAN_DAYS = "DIET_SET_CONFIRM_MODAL_DATA_ON_DELETE_DIET_PLAN_DAYS";
export const DIET_CLEAR_CONFIRM_MODAL_DATA = "DIET_CLEAR_CONFIRM_MODAL_DATA";
export const DIET_SET_TEMPLATE_TITLE = "DIET_SET_TEMPLATE_TITLE";
export const DIET_SET_TEMPLATE_SAVE_AS_TITLE = "DIET_SET_TEMPLATE_SAVE_AS_TITLE";
export const DIET_CLEAR_TEMPLATE_MODAL_DATA = "DIET_CLEAR_TEMPLATE_MODAL_DATA";
export const DIET_FILTER_SELECTED_MEALS_TIMING = "DIET_FILTER_SELECTED_MEALS_TIMING";
export const DIET_OPEN_EDIT_MEAL_INPUT_FIELDS = "DIET_OPEN_EDIT_MEAL_INPUT_FIELDS";
export const DIET_CLOSE_EDIT_MEAL_INPUT_FIELDS = "DIET_CLOSE_EDIT_MEAL_INPUT_FIELDS";
export const DIET_SET_EDIT_MEALS_INPUT_TEXT = "DIET_SET_EDIT_MEALS_INPUT_TEXT";
export const DIET_SET_ERRORS_IN_EDITED_MEAL_INPUT_FIELD = "DIET_SET_ERRORS_IN_EDITED_MEAL_INPUT_FIELD";
