import {
	DOCTOR_SET_SUPPORT_CODE,
	DOCTOR_CREATE_ANSWER,
	DOCTOR_SET_INPUT_TEXT,
	DOCTOR_SET_VALIDATION_ERROR,
	DOCTOR_SET_TERMS_ACCEPT_CHECKBOX,
	DOCTOR_OPEN_ANSWER_FIELD,
	DOCTOR_SET_ANSWER_TEXT,
	DOCTOR_CLOSE_ANSWER_FIELD,
	DOCTOR_SET_USER_CREDENTIALS,
	DOCTOR_SET_TERMS_OF_USE,
	DOCTOR_CLEAR_CHANGED_PASSWORD_INPUTS_TEXTS,
	DOCTOR_SET_INITIAL_TEST_QUESTIONS_DATA,
	DOCTOR_CLEAR_LOCAL_STATE,
	RESET_INITIAL_STATE_DATA,
} from "~/actions/types";

const LOCAL_STATE = {
	email: "",
	password: "",
	current_password: "",
	new_password: "",
	confirm_password: "",
	errors: {},
	answer: "",
	password: "",
	selected_answer_no: "",
	terms_accepted: false,
	support_code: "",
	doctor_support_code: "",
};

const STORED_STATE = {
	datalist: {},
	code: window.__INITIAL_STATE__ || "",
	doctor_credentials: {
		id_code: 0,
		consultation_role: "",
		main_streams: "",
		educations: "",
		user_name: "",
	},
	terms_of_use: null,
};

const INITIAL_STATE = {
	...JSON.parse(JSON.stringify(LOCAL_STATE)),
	...STORED_STATE,
};

export const DoctorReducer = (state = INITIAL_STATE, action) => {
	let newState = Object.assign({}, state);

	if (action.type === RESET_INITIAL_STATE_DATA) {
		return INITIAL_STATE;
	}

	if (action.type === DOCTOR_CLEAR_LOCAL_STATE) {
		newState = { ...state, ...JSON.parse(JSON.stringify(LOCAL_STATE)) };
		return newState;
	}

	if (action.type === DOCTOR_SET_USER_CREDENTIALS) {
		newState.doctor_credentials = { ...action.data };
		return newState;
	}

	// handle all input values
	if (action.type === DOCTOR_SET_INPUT_TEXT) {
		if (action.input_name === "email") newState.email = action.input_value;
		else if (action.input_name === "password") newState.password = action.input_value;
		else if (action.input_name === "current_password") newState.current_password = action.input_value;
		else if (action.input_name === "new_password") newState.new_password = action.input_value;
		else if (action.input_name === "confirm_password") newState.confirm_password = action.input_value;
		else if (action.input_name === "support_code") newState.support_code = action.input_value;
		return newState;
	}

	// clearing changed password input texts after
	if (action.type === DOCTOR_CLEAR_CHANGED_PASSWORD_INPUTS_TEXTS) {
		newState.current_password = "";
		newState.confirm_password = "";
		newState.new_password = "";
		return newState;
	}

	// putting validation errors
	if (action.type === DOCTOR_SET_VALIDATION_ERROR) {
		newState.errors = { ...action.errors };
		return newState;
	}

	// doctor generate support code screen
	if (action.type == DOCTOR_SET_SUPPORT_CODE) {
		newState.doctor_support_code = action.data.hashcode;
		return newState;
	}

	// terms of use screen

	if (action.type === DOCTOR_SET_TERMS_OF_USE) {
		newState.terms_of_use = action.data;
		return newState;
	}

	if (action.type === DOCTOR_SET_TERMS_ACCEPT_CHECKBOX) {
		newState.terms_accepted = action.value;
		return newState;
	}

	// doctor test question screen
	if (action.type === DOCTOR_SET_INITIAL_TEST_QUESTIONS_DATA) {
		newState.datalist = action.data;
		return newState;
	}

	if (action.type === DOCTOR_OPEN_ANSWER_FIELD) {
		newState.selected_answer_no = action.answer_no;
		return newState;
	}

	if (action.type === DOCTOR_SET_ANSWER_TEXT) {
		newState.answer = action.answer;
		return newState;
	}

	if (action.type === DOCTOR_CLOSE_ANSWER_FIELD) {
		newState.selected_answer_no = "";
		newState.answer = "";
		return newState;
	}

	if (action.type == DOCTOR_CREATE_ANSWER) {
		newState.datalist = {
			...state.datalist,
			questions: state.datalist.questions.map((answer) => (answer.answer_no === newState.selected_answer_no ? { ...answer, answer: newState.answer } : answer)),
		};
		newState.selected_answer_no = "";
		newState.answer = "";
		return newState;
	}

	return state;
};
