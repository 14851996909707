import React, { memo } from "react";

export default memo(({ fat }) => (
	<footer>
		{fat ? (
			<div className="uk-container uk-width-2-3@l uk-width-4-5@m uk-margin-small-bottom">
				<div className="uk-grid uk-flex-center">
					<div className="uk-width-1-5@s uk-width-1-2 footerLinks">
						<h3>Healthmug</h3>
						<a href="/register">Join us</a>
						<a href="https://www.healthmug.com/info/about-us">About us</a>
						<a href="https://www.healthmug.com/info/contact-us">Contact us</a>
					</div>
					<div className="uk-width-1-5@s uk-width-1-2 footerLinks">
						<h3>Stay Connected</h3>
						<a href="https://www.facebook.com/healthmug/">
							<i uk-icon="icon:facebook;ratio:0.6" /> Facebook
						</a>
						<a href="https://twitter.com/healthmug">
							<i uk-icon="icon:twitter;ratio:0.6" /> Twitter
						</a>
					</div>
					<div className="uk-width-3-5@s uk-width-1-1 footerLinks">
						<h3>Support</h3>
						<div className="uk-grid uk-child-width-1-2@s">
							<div className="uk-margin-small-bottom">
								<a href="mailto:seller-support@healthmug.com" className="uk-margin-small-top uk-margin-remove-top">
									<i uk-icon="icon:mail;ratio:0.8" /> doctor-support@healthmug.com
								</a>

								<a>
									<i uk-icon="icon:receiver;ratio:0.8" /> 7838389846 (10am - 6pm)
								</a>
							</div>
							<div>
								<p>
									Healthmug.com
									<br />
									X-35, Okhla Industrial Area,Phase II,
									<br />
									New Delhi - 110020
								</p>
							</div>
						</div>
					</div>

					<div className="uk-width-1-1">
						<p>
							HEALTHMUG offers the Consultants/Health Care Providers (" HCP" ) a platform to set up and operate their online/virtual 'clinics' and offer their services to their patients.
						</p>

						<p>
							The platform includes features that allow doctors to maintain an online presence on the site, made available and accessible to other Users, answer patient queries, look up
							their patients' medical profile and history during a consultation, and conduct paid and free consultations with their patients.
						</p>

						<p>The HEALTHMUG APP is to connect with their patients and help those in need of health and well-being advice.</p>
					</div>
				</div>
			</div>
		) : null}
		<div className="uk-container">
			<div className="copyright">
				<img src="https://static.oxinis.com/hub/image/layout/logo-healthmug.png" />
				<span>Copyright© {new Date().getFullYear()}. All rights reserved.</span>
			</div>
		</div>
	</footer>
));
