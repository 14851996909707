import { ProjectUrl } from "~/env";
import Alertify from "~/scripts/alertify";

import {
	DOCTOR_SET_SUPPORT_CODE,
	DOCTOR_CREATE_ANSWER,
	DOCTOR_SET_INPUT_TEXT,
	DOCTOR_SET_VALIDATION_ERROR,
	DOCTOR_SET_TERMS_ACCEPT_CHECKBOX,
	DOCTOR_OPEN_ANSWER_FIELD,
	DOCTOR_SET_ANSWER_TEXT,
	DOCTOR_CLOSE_ANSWER_FIELD,
	DOCTOR_SET_TERMS_OF_USE,
	DOCTOR_CLEAR_CHANGED_PASSWORD_INPUTS_TEXTS,
	DOCTOR_SET_INITIAL_TEST_QUESTIONS_DATA,
	DOCTOR_CLEAR_LOCAL_STATE,
	DOCTOR_SET_USER_CREDENTIALS,
	RESET_INITIAL_STATE_DATA,
} from "~/actions/types";

/** @desc function for clearing local state on mounting */
export const DoctorClearLocalState = () => ({ type: DOCTOR_CLEAR_LOCAL_STATE });

/** @desc function for setting user credentials */
export const DoctorSetUserCredentials = (data) => ({ type: DOCTOR_SET_USER_CREDENTIALS, data });

/** @desc function for getting the doctor credentials */
export const DoctorGetUserCredentials = () => () => {
	return fetch(ProjectUrl + "/doctor/getdoctordetails").then((res) =>
		(res.ok ? res.json() : Promise.reject())
			.then((data) => {
				return Promise.resolve(data);
			})
			.catch((error) => {
				if (error instanceof Error) Alertify.error(error.message);
				else if (error !== undefined && error !== null) error.text().then((data) => Alertify.error(data));
				else Alertify.error("An error has occurred");
			})
	);
};

/** @desc function for changing the text input of email password etc on typing */
export const DoctorSetInputText = (input_name, input_value) => ({ type: DOCTOR_SET_INPUT_TEXT, input_name, input_value });

/** @desc function for putting validation error on forms input */
export const DoctorSetValidationError = (errors) => ({ type: DOCTOR_SET_VALIDATION_ERROR, errors });

//#region doctor user login

/** @desc function for letting doctor login to server  */
export const DoctorUserLogin = () => (dispatch, getState) => {
	const { email, password, errors } = getState().doctor_store;
	const { validationErrors, someErrorState } = _DoctorSetValidationUserLogin(email, password, errors);

	if (Object.keys(validationErrors).length > 0) {
		if (someErrorState) {
			dispatch({ type: DOCTOR_SET_VALIDATION_ERROR, errors: validationErrors });
		}
		return Promise.reject();
	} else {
		return fetch(ProjectUrl + "/doctor/userlogin", {
			method: "POST",
			credentials: "include",
			headers: { "content-type": "application/json", "X-Requested-With": "XMLHttpRequest" },
			body: JSON.stringify({ email: email, pwd: password }),
		})
			.then((res) => (res.ok ? res.json() : Promise.reject(res)))
			.then((response) => {
				if (!response.logged_in) {
					Alertify.error("User Id or Password is incorrect");
					return Promise.reject();
				}
				return Promise.resolve(response);
			})
			.catch((error) => {
				if (error instanceof Error) Alertify.error(error.message);
				else if (error !== undefined || error !== null) error.text().then((data) => Alertify.error(data));
				else Alertify.error("An error has occurred");
				return Promise.reject();
			});
	}
};

/** @desc function for sending the request for resetting the password to server */
export const DoctorForgotPassword = () => (dispatch, getState) => {
	const { email, errors } = getState().doctor_store;
	const validationErrors = _DoctorSetValidationForgotPassword(email, errors);
	if (Object.keys(validationErrors).length > 0) {
		if (errors.email !== validationErrors.email) {
			dispatch({ type: DOCTOR_SET_VALIDATION_ERROR, errors: validationErrors });
		}
		return Promise.reject();
	} else {
		return fetch(ProjectUrl + "/doctor/forgotpasswordlink", {
			method: "POST",
			credentials: "include",
			headers: { "content-type": "application/json", "X-Requested-With": "XMLHttpRequest" },
			body: JSON.stringify({ email: email }),
		})
			.then((res) => (res.ok ? res.text() : Promise.reject(res)))
			.catch((error) => {
				if (error instanceof Error) Alertify.error(error.message);
				else if (error !== undefined || error !== null) error.text().then((data) => Alertify.error(data));
				else Alertify.error("An error has occurred");
				return Promise.reject();
			});
	}
};

/** @desc function for putting validation on user login form */
const _DoctorSetValidationUserLogin = (email, password, errors) => {
	let someErrorState = false;
	const validationErrors = {};
	const email_validator = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,3})$/;

	if (email.trim().length === 0) {
		validationErrors.email = "Email should not be empty";
		if (errors.email !== "Email should not be empty" && !someErrorState) someErrorState = true;
	} else if (!email_validator.test(email)) {
		validationErrors.email = "Invalid email!";
		if (errors.email !== "Invalid email!" && !someErrorState) someErrorState = true;
	}

	if (password.trim() === "") {
		validationErrors.password = "Please enter the password";
		if (errors.password !== "Please enter the password" && !someErrorState) someErrorState = true;
	}
	return { validationErrors, someErrorState };
};

/** @desc function for putting validation on forgot password form */
const _DoctorSetValidationForgotPassword = (email) => {
	const validationErrors = {};
	const email_validator = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,3})$/;

	if (email.trim().length == 0) {
		validationErrors.email = "Email should not be empty";
	} else if (!email_validator.test(email)) {
		validationErrors.email = "Invalid email!";
	}
	return validationErrors;
};
//#endregion

//#region doctor reset password

/** @desc function for resetting the password of doctor login to server */
export const DoctorResetPassword = () => (dispatch, getState) => {
	const { email, password, confirm_password, errors, code } = getState().doctor_store;
	const { validationErrors, someErrorState } = _DoctorSetValidationResetPassword(email, password, confirm_password, errors);

	if (Object.keys(validationErrors).length > 0) {
		if (someErrorState) {
			dispatch({ type: DOCTOR_SET_VALIDATION_ERROR, errors: validationErrors });
		}
		return Promise.reject();
	} else {
		return fetch(ProjectUrl + "/doctor/setpassword", {
			method: "POST",
			credentials: "include",
			headers: { "content-type": "application/json", "X-Requested-With": "XMLHttpRequest" },
			body: JSON.stringify({ email: email, password: password, code: code }),
		})
			.then((res) => (res.ok ? res.json() : Promise.reject(res)))
			.then((data) => {
				if (!data.status) {
					return Promise.reject(data);
				}
			})
			.catch((error) => {
				if (error instanceof Error) Alertify.error(error.message);
				else if (error !== undefined || error !== null) error.text().then((data) => Alertify.error(data));
				else Alertify.error("An error has occurred");
				return Promise.reject();
			});
	}
};

/** @desc function for putting validation on resetting password on reset password form */
const _DoctorSetValidationResetPassword = (email, password, confirm_password, errors) => {
	let someErrorState = false;
	const validationErrors = {};

	let email_validator = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,3})$/;

	let strict_password_validator = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#!@$&_~-])[a-zA-Z0-9#!@$&_~-]{10,}$/;

	if (email.trim().length === 0) {
		validationErrors.email = "Email cannot be empty";
		if (errors.email !== validationErrors.email && !someErrorState) someErrorState = true;
	} else if (!email_validator.test(email)) {
		validationErrors.email = "Invalid email!";
		if (errors.email !== validationErrors.email && !someErrorState) someErrorState = true;
	}

	if (password.trim() === "") {
		validationErrors.password = "Please enter new password";
		if (errors.password !== validationErrors.password && !someErrorState) someErrorState = true;
	} else if (password.trim().length < 10) {
		validationErrors.password = "Password should be of atleast 10 characters";
		if (errors.password !== validationErrors.password && !someErrorState) someErrorState = true;
	} else if (!strict_password_validator.test(password)) {
		validationErrors.password = "Password should have atleast one capital letter, small letter, number and special character";
		if (errors.password !== validationErrors.password && !someErrorState) someErrorState = true;
	}

	if (confirm_password.trim().length === 0) {
		validationErrors.confirm_password = "Please confirm your new password";
		if (errors.confirm_password !== validationErrors.confirm_password && !someErrorState) someErrorState = true;
	} else if (password.trim() !== confirm_password.trim()) {
		validationErrors.confirm_password = "Confirm Password does not match the New Password";
		if (errors.confirm_password !== validationErrors.confirm_password && !someErrorState) someErrorState = true;
	}

	return { validationErrors, someErrorState };
};
//#endregion

//#region doctor change password

/** @desc function for changing the password of doctor login details to server */
export const DoctorChangePassword = () => (dispatch, getState) => {
	const { current_password, new_password, confirm_password, errors } = getState().doctor_store;
	const { validationErrors, someErrorState } = _DoctorSetValidationChangePassword(current_password, new_password, confirm_password, errors);
	if (Object.keys(validationErrors).length > 0) {
		if (someErrorState) {
			dispatch({ type: DOCTOR_SET_VALIDATION_ERROR, errors: validationErrors });
		}
		return Promise.reject();
	} else {
		return fetch(ProjectUrl + "/doctor/setnewpassword", {
			method: "POST",
			credentials: "include",
			headers: { "content-type": "application/json", "X-Requested-With": "XMLHttpRequest" },
			body: JSON.stringify({
				oldpassword: current_password,
				newpassword: new_password,
				confirmpassword: confirm_password,
			}),
		})
			.then((res) => (res.ok ? res : Promise.reject(res)))
			.then(() => {
				Alertify.success("Password Changed Successfully");
				dispatch({ type: DOCTOR_CLEAR_CHANGED_PASSWORD_INPUTS_TEXTS });
			})
			.catch((error) => {
				if (error instanceof Error) Alertify.error(error.message);
				else if (error !== undefined || error !== null) error.text().then((data) => Alertify.error(data));
				else Alertify.error("An error has occurred");
			});
	}
};

/** @desc function for putting validation on change password form */
const _DoctorSetValidationChangePassword = (current_password, new_password, confirm_password, errors) => {
	let someErrorState = false;
	const validationErrors = {};

	let strict_password_validator = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#!@$&_~-])[a-zA-Z0-9#!@$&_~-]{10,}$/;

	if (current_password.trim() === "") {
		validationErrors.current_password = "Please enter old password";
		if (errors.current_password !== validationErrors.current_password && !someErrorState) someErrorState = true;
	} else if (current_password.trim().length < 8) {
		validationErrors.current_password = "Minimum password should be of 8 characters";
		if (errors.current_password !== validationErrors.current_password && !someErrorState) someErrorState = true;
	}

	if (new_password.trim() === "") {
		validationErrors.new_password = "Please enter new password";
		if (errors.new_password !== validationErrors.new_password && !someErrorState) someErrorState = true;
	} else if (new_password.trim().length < 10) {
		validationErrors.new_password = "Minimum password should be of 10 characters";
		if (errors.new_password !== validationErrors.new_password && !someErrorState) someErrorState = true;
	} else if (!strict_password_validator.test(new_password)) {
		validationErrors.new_password = "Password should have atleast one capital letter, small letter, number and special character";
		if (errors.new_password !== validationErrors.new_password && !someErrorState) someErrorState = true;
	}

	if (confirm_password.trim().length === 0) {
		validationErrors.confirm_password = "Please confirm your new password";
		if (errors.confirm_password !== validationErrors.confirm_password && !someErrorState) someErrorState = true;
	} else if (new_password.trim() !== confirm_password.trim()) {
		validationErrors.confirm_password = "Confirm Password does not match the New Password";
		if (errors.confirm_password !== validationErrors.confirm_password && !someErrorState) someErrorState = true;
	}
	return { validationErrors, someErrorState };
};
//#endregion

//#region doctor support login

/** @desc function for support login to server */
export const SupportLogin = () => (dispatch, getState) => {
	const { email, support_code, errors } = getState().doctor_store;
	const { validationErrors, someErrorState } = _DoctorSetValidationSupportLogin(email, support_code, errors);

	if (Object.keys(validationErrors).length > 0) {
		if (someErrorState) {
			dispatch({ type: DOCTOR_SET_VALIDATION_ERROR, errors: validationErrors });
		}
		return Promise.reject();
	} else {
		return fetch(ProjectUrl + "/doctor/logindoctorsupport", {
			method: "POST",
			credentials: "include",
			headers: { "content-type": "application/json", "X-Requested-With": "XMLHttpRequest" },
			body: JSON.stringify({ email: email, pwd: support_code }),
		})
			.then((res) => (res.ok ? res.json() : Promise.reject(res)))
			.then((data) => {
				if (!data.logged_in) {
					return Promise.reject();
				}
			})
			.catch((error) => {
				if (error instanceof Error) Alertify.error(error);
				else if (error !== undefined || error !== null) error.text().then((data) => Alertify.error(data));
				else Alertify.error("An error has occurred");
				return Promise.reject();
			});
	}
};

/** @desc  function for putting validation on support login form */
const _DoctorSetValidationSupportLogin = (email, support_code, errors) => {
	let someErrorState = false;
	let validationErrors = {};
	let email_validator = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,3})$/;

	if (email.trim().length === 0) {
		validationErrors.email = "Email required!";
		if (errors.email !== validationErrors.email && !someErrorState) someErrorState = true;
	} else if (!email_validator.test(email)) {
		validationErrors.email = "Invalid email!";
		if (errors.email !== validationErrors.email && !someErrorState) someErrorState = true;
	}

	if (support_code.trim() === "") {
		validationErrors.support_code = "Support code required!";
		if (errors.support_code !== validationErrors.support_code && !someErrorState) someErrorState = true;
	}

	return { validationErrors, someErrorState };
};
//#endregion

//#region doctor generate support code

/** @desc function for generating support code for support login */
export const DoctorGenerateSupportCode = () => (dispatch) => {
	return fetch(ProjectUrl + "/doctor/generatedoctorsupportcode", {
		headers: { "X-Requested-With": "XMLHttpRequest" },
	})
		.then((res) => (res.ok ? res.json() : Promise.reject(res)))
		.then((data) => {
			dispatch({ type: DOCTOR_SET_SUPPORT_CODE, data });
		})
		.catch((error) => {
			if (error instanceof Error) Alertify.error(error);
			else if (error !== undefined || error !== null) error.text().then((data) => Alertify.error(data));
			else Alertify.error("An error has occurred");
		});
};
//#endregion

//#region doctor terms of use

/** @desc function for getting the doctor accepted terms value */
export const DoctorGetTermsOfUse = () => (dispatch) => {
	dispatch({ type: DOCTOR_CLEAR_LOCAL_STATE });

	return fetch(ProjectUrl + "/doctor/gettermsofuse", {
		headers: { "X-Requested-With": "XMLHttpRequest" },
	})
		.then((res) => (res.ok ? res.json() : Promise.reject(res)))
		.then((data) => dispatch({ type: DOCTOR_SET_TERMS_OF_USE, data }))
		.catch((error) => {
			if (error instanceof Error) Alertify.error(error.message);
			else if (error !== undefined || error !== null) error.text().then((data) => Alertify.error(data));
			else Alertify.error("An error has occurred");
		});
};

/** @desc function for toggling terms accepted checkbox */
export const DoctorSetTermsAcceptCheckbox = (value) => ({ type: DOCTOR_SET_TERMS_ACCEPT_CHECKBOX, value });

/** @desc function for saving terms accepted by doctor */
export const DoctorAcceptTermsUse = () => () => {
	return fetch(ProjectUrl + "/doctor/acceptterms", {
		method: "POST",
		credentials: "include",
		headers: { "content-type": "application/json", "X-Requested-With": "XMLHttpRequest" },
	})
		.then((res) => (res.ok ? res.text() : Promise.reject(res)))
		.catch((error) => {
			if (error instanceof Error) Alertify.error(error.message);
			else if (error !== undefined || error !== null) error.text().then((data) => Alertify.error(data));
			else Alertify.error("An error has occurred");
		});
};
//#endregion

//#region doctor test question screen

/** @desc function for getting initial data of test questions */
export const DoctorGetInitialTestQuestionsData = () => (dispatch) => {
	dispatch({ type: DOCTOR_CLEAR_LOCAL_STATE });

	return fetch(ProjectUrl + "/doctor/gettestquestions", {
		headers: { "X-Requested-With": "XMLHttpRequest" },
	})
		.then((res) => (res.ok ? res.json() : Promise.reject(res)))
		.then((data) => dispatch({ type: DOCTOR_SET_INITIAL_TEST_QUESTIONS_DATA, data }))
		.catch((error) => {
			if (error instanceof Error) Alertify.error(error.message);
			else if (error !== undefined || error !== null) error.text().then((data) => Alertify.error(data));
			else Alertify.error("An error has occurred");
		});
};

/** @desc function for changing answer text on typing */
export const DoctorSetAnswerText = (answer) => ({ type: DOCTOR_SET_ANSWER_TEXT, answer });

/** @desc function for opening the answer field */
export const DoctorOpenAnswerField = (answer_no) => ({ type: DOCTOR_OPEN_ANSWER_FIELD, answer_no });

/** @desc function for closing the answer field */
export const DoctorCloseAnswerField = () => ({ type: DOCTOR_CLOSE_ANSWER_FIELD });

/** @desc function for submitting answer to server */
export const DoctorCreateAnswer = () => (dispatch, getState) => {
	const { selected_answer_no, answer } = getState().doctor_store;

	return fetch(ProjectUrl + "/doctor/updatetestanswer", {
		method: "POST",
		credentials: "include",
		headers: { "content-type": "application/json", "X-Requested-With": "XMLHttpRequest" },
		body: JSON.stringify({ answer_no: selected_answer_no, answer }),
	})
		.then((res) => (res.ok ? res.json() : Promise.reject(res)))
		.then(() => {
			dispatch({ type: DOCTOR_CREATE_ANSWER });
		})
		.catch((error) => {
			if (error instanceof Error) Alertify.error(error.message);
			else if (error !== undefined || error !== null) error.text().then((data) => Alertify.error(data));
			else Alertify.error("An error has occurred");
		});
};
//#endregion

/** @desc function for logging out user */
export const DoctorLogOutUser = () => (dispatch) => {
	// resetting state to its initial values on logging out
	dispatch({ type: RESET_INITIAL_STATE_DATA });

	return fetch(ProjectUrl + "/doctor/logout", {
		headers: { "X-Requested-With": "XMLHttpRequest" },
	})
		.then((res) => (res.ok ? res.text() : Promise.reject(res)))
		.catch((error) => {
			if (error instanceof Error) Alertify.error(error.message);
			else if (error !== undefined || error !== null) error.text().then((data) => Alertify.error(data));
			else Alertify.error("An error has occurred");
		});
};
