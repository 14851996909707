import React, { forwardRef } from "react";

const Input = ({ className, style, error, label, forwardedRef, ...others }) => {
	const classList = className ? `inputWrap ${className}` : "inputWrap";
	return (
		<div className={classList} style={style}>
			<div>
				<label className={error ? "labelError" : null}>{label}</label>
				<input ref={forwardedRef} {...others} className={error ? "uk-input uk-input-error" : "uk-input"} />
			</div>
			<p className="error">{error}</p>
		</div>
	);
};

export default forwardRef((props, ref) => {
	return <Input {...props} forwardedRef={ref} />;
});
